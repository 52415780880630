:root {
  --primary-contrast: rgba(255, 255, 255, 1);
  --primary: rgba(17, 24, 39, 1);
  --secondary-contrast: rgb(175, 175, 175);
  --secondary: rgba(17, 24, 39, 1);
}

* {
  font-family: "Open Sans";
}

body {
  margin: 0;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto`', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.advanced-select > div:last-child {
  border: var(--bs-border-width) solid var(--bs-border-color);
}


.form-control.rs-picker-daterange:hover  {
  --rs-input-focus-border: var(--bs-border-color);
}

.form-control.rs-picker-daterange > .rs-input-group:active, .form-control.rs-picker-daterange > .rs-input-group.rs-input-group-focus {
  /* Copied from bootstrap form control */
  --bs-border-color: #86b7fe;
  border-color: #86b7fe;
  outline: none;
  box-shadow: 0 0 0 .32rem rgba(13, 110, 253, .25);
}

.form-control.rs-picker-daterange > .rs-input-group {
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  border: 0px;
  border-radius: var(--bs-border-radius);
}

.form-control.rs-picker-daterange input {
  font-size: 1rem;
}