html, #root {
    background-color: rgba(242, 242, 242, 1);
}

.cleverapply-app {
    --block-primary: rgb(255, 255, 255);
    --block-primary-contrast: rgb(0, 0, 0);
    --block-secondary: rgb(242, 242, 242);
    --block-secondary-contrast: rgb(0, 0, 0);
    --header-bg: rgba(37, 99, 235, 1);

    background-color: rgba(242, 242, 242, 1);

    .header .nav-link:not(.default).active {
        border-bottom: 1px solid var(--primary-contrast);
        background-color: rgba(255, 255, 255, .05);
    }

    .header .nav-link:not(.default):hover {
        border-bottom: 1px solid var(--primary-contrast);
        background-color: rgba(0, 0, 0, .75);
    }

    .header .nav-link:not(.default) {
        border-bottom: 1px solid transparent;
        --bs-nav-link-color: var(--primary-contrast);
        --bs-nav-link-hover-color: var(--primary-contrast);
        --bs-nav-link-active-color: var(--primary-contrast);
        --bs-navbar-nav-link-padding-x: 24px;
        height: 40px;
    }

    .header.navbar {
        --bs-navbar-color: var(--primary-contrast);
        --bs-navbar-hover-color: var(--primary-contrast);
        --bs-navbar-active-color: var(--primary-contrast);
        --bs-body-bg: var(--header-bg);
        background-color: var(--header-bg);

        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: 10;
    }

    .footer.navbar {
        padding: 20px !important;
        --bs-navbar-color: var(--secondary-contrast);
        --bs-navbar-hover-color: var(--secondary-contrast);
        --bs-body-bg: var(--primary);
        background-color: var(--primary);
        color: var(--secondary-contrast);
    }

    .auth-actions .btn {
        padding: 8px 24px;
    }

    .auth-actions .btn-primary {
        --bs-btn-bg: transparent;
        --bs-btn-border-color: transparent;
        --bs-btn-border-radius: 24px;
    }

    .side-nav {
        background-color: rgba(41, 41, 41, 1);
        width: 170px;
        z-index: 10;
        position: fixed;
        left: 0;
        top: 70px;
        bottom: 0;

        @media (max-width: 993px) {
            display: none;

            &.active {
                display: block;
            }
        }

        .break {
            display: block;
            height: 10px;
            width: 100%;
        }

        a.button {
            display: block;
            width: 100%;
            color: rgba(255, 255, 255, 1);
            text-decoration: none;
            padding: 8px 30px 9px 24px;
            font-size: 14px;
            font-weight: 600;
            transition: background-color .2s;
        }

        a.button:hover {
            background-color: var(--header-bg);
        }
    }

    min-height: 100dvh;
    &:not(.no-nav) {
        min-height: calc(100dvh - 70px);

        main {
            margin-top: 70px;
    
            @media (min-width: 992px) {
                margin-left: 170px;
            }
        }
    }
    
}